<template>
  <div class="home-banner">
    <XtxCarousel auto-play :sliders="sliders" />
  </div>
</template>
<script>
export default {
  name: 'HomeBanner',
  setup () {
    // 获取轮播图数据
    const sliders = [
      { id: '5', imgUrl: 'https://lvyou-wx.oss-cn-hangzhou.aliyuncs.com/static/banner5.png' },
      { id: '1', imgUrl: 'https://lvyou-wx.oss-cn-hangzhou.aliyuncs.com/static/banner1.png' },
      { id: '2', imgUrl: 'https://lvyou-wx.oss-cn-hangzhou.aliyuncs.com/static/banner2.png' },
      { id: '3', imgUrl: 'https://lvyou-wx.oss-cn-hangzhou.aliyuncs.com/static/banner3.png' },
      { id: '4', imgUrl: 'https://lvyou-wx.oss-cn-hangzhou.aliyuncs.com/static/banner4.png' }
    ]
    return { sliders }
  }
}
</script>
<style scoped lang="less">
.home-banner {
  width: 1200px;
  height: 800px;
  margin-top: 15px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 98
}
// 覆盖样式
.xtx-carousel {
  :deep(.carousel-btn.prev){
    left: 100px;
  }
  /* :deep(.carousel-indicator) {
    padding-left: 100px;
  } */
}
</style>
